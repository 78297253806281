import React, { Component } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
  cilLocationPin,
  cilPeople,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  /* {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  }, */
  {
    component: CNavTitle,
    name: "Election Panel",
  },
  {
    component: CNavItem,
    name: "Properties",
    to: "/election/properties",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Election",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Election Types",
        to: "/election/types",
      },
      {
        component: CNavItem,
        name: "Elections",
        to: "/elections",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Location",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Countries",
        to: "/election/countries",
      },
      {
        component: CNavItem,
        name: "States",
        to: "/election/states",
      },
      {
        component: CNavItem,
        name: "Districts",
        to: "/election/districts",
      },
      {
        component: CNavItem,
        name: "Cities",
        to: "/election/cities",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Constituency",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Master List",
        //icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
        to: "/election/constituencies",
      },
      {
        component: CNavItem,
        name: "Tehsils",
        to: "/election/tehsils",
      },
      {
        component: CNavItem,
        name: "Extensions",
        // icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
        to: "/election/constituencies/extensions",
      },
      {
        component: CNavItem,
        name: "Constituency Tehsils",
        // icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
        to: "/election/constituencies/tehsils",
      },
      {
        component: CNavItem,
        name: "Regions",
        to: "/election/regions",
      },
    ],
  },

  {
    component: CNavItem,
    name: "Parties",
    to: "/election/parties",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: "Candidate Result",
  //   to: "/election/candidate-result",
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },

  {
    component: CNavItem,
    name: "Alliances",
    to: "/election/alliances",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Alliance Parties",
    to: "/election/alliances-parties",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },

  // Candiadte urls

  {
    component: CNavGroup,
    name: "Candidate Details",
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Caste-Category",
        to: "/election/caste-category",
      },
      {
        component: CNavItem,
        name: "Candidates",
        to: "/election/candidates",
      },
      {
        component: CNavItem,
        name: "Status",
        to: "/election/candidate-status",
      },
      {
        component: CNavItem,
        name: "Extension",
        to: "/election/candidate-extension",
      },
    ],
  },
];

export default _nav;
